import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import ActionTypes from "./admin_user_action_types";

const initialState = {
    data: [],
    loadStatus: STATUS.IDLE,
    saveStatus: STATUS.IDLE,
    saveError: null,
    roles: []
};

const handleLoadRequest = (state) => ({
    ...state,
    loadStatus: STATUS.PENDING
});

const handleLoadFailure = (state) => ({
    ...state,
    loadStatus: STATUS.REJECTED
});

const handleLoadSuccess = (state, {payload}) => ({
    ...state,
    loadStatus: STATUS.RESOLVED,
    data: payload
});

const handleSaveRequest = (state) => ({
    ...state,
    saveStatus: STATUS.PENDING,
    saveError: []
});

const handleSaveFailure = (state, {saveError}) => ({
    ...state,
    saveStatus: STATUS.REJECTED,
    saveError
});

const handleSaveSuccess = (state) => ({
    ...state,
    saveStatus: STATUS.RESOLVED,
    saveError: []
});

const handleLoadRoles = (state, {payload}) => ({
    ...state,
    roles: payload
});

const handlers = {
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure,
    [ActionTypes.SAVE_REQUEST]: handleSaveRequest,
    [ActionTypes.SAVE_SUCCESS]: handleSaveSuccess,
    [ActionTypes.SAVE_FAILURE]: handleSaveFailure,
    [ActionTypes.LOAD_ROLES]: handleLoadRoles
};
export default createReducer(initialState, handlers);
