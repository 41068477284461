// @ts-check
/**
 * @fileoverview API for timeslots service
 */

import axiosClient from "../../middleware/axios.middelware";

/** @typedef {import("axios").AxiosResponse<{roles: Array<{name: string}>}>} AxiosResponseRoles*/
/** @typedef {import("axios").AxiosResponse<{ok: boolean, data: Array<UserAPI>}>} AxiosResponseUsersAPI*/

/**
 * fetch users
 *
 * @param {Object} params
 * @param {String} organizationId
 * @return {Promise<AxiosResponseUsersAPI>}
 */
function fetchUsersAPI(params, organizationId) {
    const URL = `${global.IDENTITY_SERVER_AUTHORITY}users`;
    return axiosClient.get(`${URL}/organizationId/${organizationId}`, {
        params
    });
}
/**
 * save user
 * @param {Object} params - user object to be saved
 * @param {"add"|"modify"} type
 * @return {Promise}
 */
function saveUserAPI(params, type) {
    const URL = `${global.IDENTITY_SERVER_AUTHORITY}users`;
    if (type === "add") {
        return axiosClient.post(URL, params);
    } else {
        const id = params.id;
        delete params.id;
        return axiosClient.patch(`${URL}/${id}`, params);
    }
}

/**
 * fetch roles
 *
 * @return {Promise<AxiosResponseRoles>}
 */
function fetchRolesAPI() {
    const URL_ROLES = `${global.IDENTITY_SERVER_AUTHORITY}roles`;

    return axiosClient.get(URL_ROLES);
}

export {fetchUsersAPI, saveUserAPI, fetchRolesAPI};
