// @ts-check
/**
 * @fileoverview API for the dayview page
 */
import {orchestratorPrefix, resourceHistoryPrefix} from "../../../config/api_config";
import axios from "../../middleware/axios.middelware";
import logger from "../../utils/logger_pino";

const urlReal = `${orchestratorPrefix}/frontend/schedule/real`;
const urlCustomer = `${orchestratorPrefix}/frontend/schedule/customer`;
const urlKi = `${orchestratorPrefix}/frontend/schedule/ki/published`;
const urlInfo = `${orchestratorPrefix}/pracroles/practitioners/role`;

const urlResourceHistoryChanges = `${resourceHistoryPrefix}/changes`;
const urlResourceHistoryResource = `${resourceHistoryPrefix}/resource`;

const errorResponse = {data: {data: [], ok: false}};

/**
 * Fetch op data from customer endpoint
 * @param {string} organizationId
 * @param {string} date
 * @param {string} email used for logging
 * @return {Promise<Array>}
 */
async function fetchCustomer(organizationId, date, email) {
    const paramsCustomer = {
        organizationId,
        date
    };
    const paramsReal = {
        organizationId,
        date
    };

    // @ts-ignore
    const [{status: realStatus, value: realValue}, {status: customerStatus, value: customerValue}] = await Promise.allSettled([
        axios.get(urlReal, {params: paramsReal}),
        axios.get(urlCustomer, {params: paramsCustomer})
    ]);

    // Send warning if the fetch call failed
    if (realStatus === "rejected") {
        logger.warn("DayView - /real call failed", {organizationId, email});
    }
    if (customerStatus === "rejected") {
        logger.warn("DayView - /customer call failed", {organizationId, email});
    }

    return [realStatus === "fulfilled" ? realValue : errorResponse, customerStatus === "fulfilled" ? customerValue : errorResponse];
}

/**
 * Fetch op data from published endpoint
 * @param {string} organizationId
 * @param {string} date
 * @param {string} email used for logging
 * @return {Promise<Array>}
 */
async function fetchKi(organizationId, date, email) {
    const params = {
        organizationId,
        date: date
    };
    // @ts-ignore
    const [{status: realStatus, value: realValue}, {status: kiStatus, value: kiValue}] = await Promise.allSettled([
        axios.get(urlReal, {params}),
        axios.get(urlKi, {params})
    ]);

    // Send warning if the fetch call failed
    if (realStatus === "rejected") {
        logger.warn("DayView - /real call failed", {organizationId, email});
    }
    if (kiStatus === "rejected") {
        logger.warn("DayView - /ki/published call failed", {organizationId, email});
    }

    return [realStatus === "fulfilled" ? realValue : errorResponse, kiStatus === "fulfilled" ? kiValue : errorResponse];
}

/**
 * @typedef {Object} QueryParamInfo
 * @property {String} organizationId
 * @property {String} availableOn in format of YYYY-MM-DD
 * @property {String} [hcServiceId]
 */

/**
 * load content for info layer
 * @param {Object} args
 * @param {string} args.organizationId
 * @param {string} args.date in form of YYYY-DD-MM
 * @param {InfoLayerSettings} args.params
 * @return {Promise}
 */
function fetchInfo({organizationId, date, params}) {
    const baseParam = {
        organizationId,
        availableOn: date
    };

    const urlInfo1 = `${urlInfo}/${params.info1.scope}/${params.info1.name}`;
    /** @type QueryParamInfo */
    const paramInfo1 = {...baseParam};
    if (params.info1.hcServiceId) {
        paramInfo1.hcServiceId = params.info1.hcServiceId;
    }

    const urlInfo2 = `${urlInfo}/${params.info2.scope}/${params.info2.name}`;
    /** @type QueryParamInfo */
    const paramInfo2 = {...baseParam};
    if (params.info2.hcServiceId) {
        paramInfo2.hcServiceId = params.info2.hcServiceId;
    }

    const urlsInfo3 = params.info3.map((item) => {
        let url = `${urlInfo}/${item.scope}/${item.name}`;
        if (item.hcServiceId) {
            url = url + `/?hcService=${item.hcServiceId}`;
        }
        return url;
    });

    return Promise.all([
        axios.get(urlInfo1, {params: paramInfo1}),
        axios.get(urlInfo2, {params: paramInfo2}),
        Promise.all(urlsInfo3.map((urlInfo3) => axios.get(urlInfo3, {params: baseParam})))
    ]);
}

/**
 * load content for info layer
 * @param {Object} args
 * @param {string} args.organizationId
 * @param {string} args.date in form of YYYY-DD-MM
 * @param {InfoParam} args.params
 * @return {Promise}
 */
function fetchPrintOnCall({organizationId, date, params}) {
    const baseParam = {
        organizationId,
        availableOn: date
    };

    let url = `${urlInfo}/${params.scope}/${params.name}`;
    if (params.hcServiceId) {
        url = url + `/?hcService=${params.hcServiceId}`;
    }

    return axios.get(url, {params: baseParam});
}
/**
 * @typedef {Object} HistoryServiceParam
 * @property {String} resource
 * @property {String} path
 * @property {Boolean} [hasIds]
 * @property {string} [changedValue]
 */

/**
 * load changes for today
 * @param {Object} args
 * @param {string} args.date in form of YYYY-DD-MM
 * @param {Array<Array<String>>} args.chunkIds
 * @param {{PARTICIPANT: HistoryServiceParam, LOCATION: HistoryServiceParam, START: HistoryServiceParam, CANCELLED: HistoryServiceParam, REVOKED: HistoryServiceParam}} args.params
 * @return {Promise}
 */
function fetchChangeHistoryForToday({date, chunkIds, params}) {
    return Promise.all(
        Object.values(params).map((value) => {
            const url = `${urlResourceHistoryChanges}/${value.resource}`;
            const param = {fromDate: date};
            if (value.hasIds) {
                param.path = value.path;
                if (value.changedValue) {
                    param.changedValue = value.changedValue;
                }
                return Promise.all(
                    chunkIds.map((ids) =>
                        axios.get(`${url}`, {
                            params: {...param, resourceIds: ids.join(",")}
                        })
                    )
                );
            } else {
                return axios.get(`${url}`, {
                    params: {...param, path: value.path, changedValue: value.changedValue}
                });
            }
        })
    );
}

/**
 * fetch resources from the history service
 * @param {object} args
 * @param {Array<Array<String>>} args.resourceChunkIds
 * @return {Promise}
 */
function fetchResources({resourceChunkIds}) {
    return Promise.all([
        Promise.all(
            resourceChunkIds.map((ids) => axios.get(`${urlResourceHistoryResource}/Appointment`, {params: {resourceIds: ids.join(",")}}))
        ),
        Promise.all(
            resourceChunkIds.map((ids) => axios.get(`${urlResourceHistoryResource}/ServiceRequest`, {params: {resourceIds: ids.join(",")}}))
        )
    ]);
}

export {fetchKi, fetchCustomer, fetchInfo, fetchPrintOnCall, fetchChangeHistoryForToday, fetchResources};
