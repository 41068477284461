// @ts-check
import {FullscreenExit, ZoomOutMap} from "@mui/icons-material";
import {Button} from "@mui/material";
import Switch from "@mui/material/Switch";
import Tooltip from "@mui/material/Tooltip";
import useMediaQuery from "@mui/material/useMediaQuery";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import {DayViewCanvas} from "../../components/day_view/day_view_canvas";
import {selectActiveDisciplineIds} from "../../components/disciplines/disciplines_selectors";
import {selectFeSettings} from "../../components/fe_settings/fe_settings_selectors";
import InfoLayer from "../../components/info_layer/info_layer";
import Legend, {SLOT_TYPE} from "../../components/legend/legend";
import {OpDetailsPopover} from "../../components/op_details_popover/op_details_popover";
import PrintLayer from "../../components/print_layer/print_layer";
import {clearNamesAction} from "../../components/private_data/private_data_actions";
import RoomsFilterAdvanced from "../../components/rooms/components/rooms_filter_advanced/rooms_filter_advanced";
import {loadRoomsAction} from "../../components/rooms/rooms_actions";
import ActionMenubar from "../../components/shared/action_menubar/action_menubar";
import DatePicker from "../../components/shared/date_picker/date_picker";
import DetailRight from "../../components/shared/detail_right/detail_right";
import Message from "../../components/shared/message/message";
import NoOp from "../../components/shared/no_op/no_op";
import Page from "../../components/shared/page/page";
import {DATE_FORMATS, DateContext} from "../../contexts/dates";
import {loadPrintDatesAction} from "../../redux/actions/print_actions";
import {selectCurrentOrganizationId, selectCurrentUserEmail, selectRefreshTrigger} from "../../redux/app_selectors";
import {isPending, isRejected, isResolved} from "../../redux/utils/status";
import logger from "../../utils/logger_pino";
import {PERMISSION, useSecurity} from "../../utils/security";
import {loadDetailOpAction} from "../op_management/op_management_actions";
import {loadTimeslotsAction} from "../timeslots/timeslots_actions";
import myStyle from "./day_view.styles";
import {changeDate, clearOpDataAction, loadChangeHistoryAction, loadInfoAction, saveVisibleDisciplines} from "./day_view_actions";
import {saveOpRooms} from "./day_view_actions";
import {loadOpDataCustomerAction, loadOpDataKiAction, saveFiltersAction} from "./day_view_actions";
import {
    selectFilters,
    selectOpData,
    selectOpDataStatus,
    selectOpRooms,
    selectSelectedDate,
    selectVisibleDisciplines
} from "./day_view_selectors";
import {getActions} from "./utils/day_view_actions_items";

const DayView = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {classes, cx} = myStyle();
    const {now, format, areSame} = useContext(DateContext);
    const {DAYVIEW_INTERVAL_DURATION_SEC} = config;
    const {isGranted} = useSecurity();

    // redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const email = useSelector(selectCurrentUserEmail);
    const selectedDate = useSelector(selectSelectedDate);
    /** @type {PlanBox[]} */
    const opData = useSelector(selectOpData);
    const status = useSelector(selectOpDataStatus);
    const opRooms = useSelector(selectOpRooms);
    const filters = useSelector(selectFilters);
    const visibleDisciplines = useSelector(selectVisibleDisciplines);
    const activeDisciplineIds = useSelector(selectActiveDisciplineIds);
    const {infoLayer: infoParams, isCustomerPlanHidden, print, emergencyThreshold} = useSelector(selectFeSettings);
    const refreshTrigger = useSelector(selectRefreshTrigger);

    const isFullscreenUser = isGranted(PERMISSION.VIEW_DAYVIEW_FULLSCREEN);
    // state
    // UI state
    const [isFullscreen, setIsFullscreen] = useState(isFullscreenUser);
    const [showFullActionMenubar, setShowFullActionMenubar] = useState(false);

    // Details Right Layer state
    /**
     * @type {[RightDetailLayer, React.Dispatch<React.SetStateAction<RightDetailLayer>>]}
     */
    const [openedLayer, setOpenedLayer] = useState();

    const handleCloseLayer = () => {
        setOpenedLayer(null);
    };

    /**
     *
     * @param {RightDetailLayer} layer
     */
    const handleOpenedLayer = (layer) => {
        if (layer === openedLayer) {
            handleCloseLayer();
        } else {
            if (layer === "print") {
                dispatch(loadPrintDatesAction(organizationId));
            }
            // openedLayer is typed as 'info' from the initialOpenLayer and it could not be overwritten with RightDetailLayer
            // @ts-ignore
            if (layer === "info") {
                loadInfo();
            }
            setOpenedLayer(layer);
        }
    };

    // OPs state
    const [selectedOp, setSelectedOp] = useState("");
    const [reloadOpData, setReloadOpData] = useState(false);

    // Customer plan state
    const [isCustomerPlan, setIsCustomerPlan] = useState(false);

    // Popover state
    const [anchorEl, setAnchorEl] = useState(null);
    const isPopoverOpen = Boolean(anchorEl);

    // Date state
    const [dateUpdateAt] = useState(now());

    useEffect(() => {
        const minuteInterval = setInterval(handlerRefetchInterval, DAYVIEW_INTERVAL_DURATION_SEC * 1000);
        return () => clearInterval(minuteInterval);
    }, [selectedDate]);

    useEffect(() => {
        if (refreshTrigger && selectedDate) {
            loadSchedule();
        }
    }, [refreshTrigger, selectedDate]);

    useEffect(() => {
        // clean up function
        return () => {
            dispatch(clearNamesAction());
        };
    }, []);

    useEffect(() => {
        if (organizationId && selectedDate) {
            loadSchedule();
            setReloadOpData(false);
        }
    }, [organizationId, selectedDate, isCustomerPlan]);

    useEffect(() => {
        if (reloadOpData) {
            loadSchedule();
            setReloadOpData(false);
        }
    }, [reloadOpData]);

    useEffect(() => {
        if (organizationId) {
            dispatch(loadRoomsAction(organizationId));
            dispatch(changeDate(now()));
            loadInfo();
        }
    }, [organizationId]);

    useEffect(() => {
        // load change histories if selected date is today and the info layer is visible
        const today = now();
        if (selectedDate && areSame(today, selectedDate, "day")) {
            dispatch(
                loadChangeHistoryAction({
                    date: format(today, DATE_FORMATS.SYSTEM_DATE),
                    changeLogOps: opData.map((op) => ({
                        id: op.id,
                        patientId: op._patient.id,
                        start: op._internalTimestamps?.duraRoomLockPre?.dtStart,
                        isEmergency: op._priority >= emergencyThreshold
                    }))
                })
            );
        }

        // If the selected date is not today and the info layer is visible, close the info layer
        if (selectedDate && !areSame(today, selectedDate, "day") && openedLayer === "info") {
            handleCloseLayer();
        }
    }, [opData, selectedDate]);

    /**
     * Handler for refetching the schedule called every DAYVIEW_INTERVAL_DURATION_SEC seconds.
     * If the current time is not the same day as dateUpdatedAt, switch to the current date (Changing the date will trigger the reloadOpData)
     */
    const handlerRefetchInterval = () => {
        const nowDateTime = now();
        if (!areSame(dateUpdateAt, nowDateTime, "day")) {
            // The forceGet parameter only works with Firefox
            // @link https://developer.mozilla.org/en-US/docs/Web/API/Location/reload
            // @ts-ignore
            window.location.reload(true);
        } else {
            setReloadOpData(true);
        }
    };

    /**
     * Handler for refetching the schedule
     */
    const loadSchedule = () => {
        const selectedDateFormatted = format(selectedDate, DATE_FORMATS.SYSTEM_DATE);
        if (isCustomerPlan) {
            dispatch(loadOpDataCustomerAction(organizationId, selectedDateFormatted));
        } else {
            dispatch(loadOpDataKiAction(organizationId, selectedDateFormatted));
        }
        dispatch(loadTimeslotsAction(organizationId, selectedDateFormatted));
    };

    const loadInfo = () => {
        if (infoParams.info1 && infoParams.info2 && infoParams.info3) {
            dispatch(loadInfoAction({organizationId, date: format(now(), DATE_FORMATS.SYSTEM_DATE), infoParams}));
        } else {
            logger.warn("infoParams are not set in settings", {
                organizationId,
                email,
                infoParams
            });
        }
    };

    /**
     * Change date handler
     * @param {DateTimeType} date - A DateTime object with the date
     */

    const handleChangeDate = (date) => {
        // clear the OpData
        dispatch(clearOpDataAction());
        dispatch(changeDate(date));
    };

    const renderHeaderOptions = () => (
        <DatePicker changeDate={handleChangeDate} disabled={isFullscreenUser} loading={isPending(status)} selectedDate={selectedDate} />
    );

    // handler for fullscreen
    const toggleIsFullscreen = () => setIsFullscreen((prevMode) => !prevMode);

    // Set actions for right action menubar
    const handlers = {
        handleOpenLegend: () => handleOpenedLayer("legend"),
        handleOpenRooms: () => handleOpenedLayer("rooms"),
        handleOpenInfo: () => handleOpenedLayer("info"),
        handleOpenPrint: () => handleOpenedLayer("print")
    };

    const disabled = {isInfoDisabled: !selectedDate || !areSame(selectedDate, now(), "day")};
    const actions = !isPending(status) ? getActions(handlers, openedLayer, disabled, print?.isEnabled) : [];

    // toggler for the Action Menu Bar
    const handleToggleActionMenubarWidth = () => setShowFullActionMenubar((prevMenuBar) => !prevMenuBar);

    /**
     * Handler for open the details layer
     * @todo #14759: find a solution without setTimeout
     * @param {object} object - Properties wrapper
     * @param {string} object.opId - The id of the appointment
     * @param {Element} object.currentTarget - The clicked element
     */
    const handleOpenDetails = ({currentTarget, opId}) => {
        dispatch(loadDetailOpAction(organizationId, opId, true));
        setSelectedOp(opId);
        const target = currentTarget;
        setTimeout(() => setAnchorEl(target), 500);
    };

    /**
     * Handler to close the popover with the OP Details
     */
    const handleClosePopover = () => {
        setAnchorEl(null);
        setSelectedOp("");
    };

    /**
     * Toggle between different op data endpoints
     */
    const toggleSourceData = () => {
        setIsCustomerPlan(!isCustomerPlan);
        setReloadOpData(true);
    };

    /**
     * Handle save rooms in filter layer
     * @param {object} params - The planSettingState
     * @param {string[]} params.roomsFilter
     * @param {string[]} params.disciplinesFilter
     * @param {string[]} params.opRooms
     */
    const handleSaveRoomsFilter = ({roomsFilter, disciplinesFilter, opRooms}) => {
        const filtersNew = {...filters};
        dispatch(
            saveFiltersAction({
                ...filtersNew,
                roomsFilter,
                disciplinesFilter
            })
        );
        dispatch(saveOpRooms(opRooms));
    };

    const small = useMediaQuery("(max-width:660px)");
    const title = small ? (isCustomerPlan ? t("DayViewPage.customer") : t("DayViewPage.ki")) : "";

    const customerPlanSwitch = (
        <>
            {small ? "" : isCustomerPlan ? t("DayViewPage.customer") : t("DayViewPage.ki")}
            <Tooltip aria-label="add" title={title}>
                <Switch
                    checked={!isCustomerPlan}
                    classes={{track: classes.track}}
                    color="primary"
                    inputProps={{"aria-label": "checkbox with default color"}}
                    onChange={toggleSourceData}
                />
            </Tooltip>
        </>
    );
    const fullscreenButton = (
        <Tooltip arrow title={isFullscreen ? t("DayViewPage.endFullscreenMode") : t("DayViewPage.fullscreenMode")}>
            <Button className={classes.zoomButton} color="inherit" onClick={toggleIsFullscreen}>
                {isFullscreen ? (
                    <FullscreenExit className={cx(classes.icon, classes.isOpened)} fontSize="small" />
                ) : (
                    <ZoomOutMap className={classes.icon} fontSize="small" />
                )}
            </Button>
        </Tooltip>
    );
    let headerItems = [<div key="switch-data">{fullscreenButton}</div>];
    if (!isCustomerPlanHidden) {
        headerItems = [
            <div key="switch-data">
                {customerPlanSwitch}
                {fullscreenButton}
            </div>
        ];
    }

    /**
     * toggle visibleDisciplines
     * @param {(string|"ALL"|"EMERGENCY")} id  The list of ids and the other disciplines possibilities
     */
    const handleToggleVisibleDisciplines = (id) => {
        if (visibleDisciplines.includes("ALL")) {
            if (id === "ALL") {
                dispatch(saveVisibleDisciplines([]));
            } else {
                // Check all ids except given id
                const activeDisciplineIdsWithEmergency = [...activeDisciplineIds, SLOT_TYPE.EMERGENCY];
                dispatch(saveVisibleDisciplines([...activeDisciplineIdsWithEmergency.filter((activeId) => activeId !== id)]));
            }
        } else if (visibleDisciplines.includes(id)) {
            // id is already selected
            if (id === "ALL") {
                dispatch(saveVisibleDisciplines([]));
            } else {
                // Remove id from the list
                dispatch(saveVisibleDisciplines([...visibleDisciplines.filter((selected) => selected !== id)]));
            }
        } else {
            if (id === "ALL") {
                dispatch(saveVisibleDisciplines([id]));
            } else {
                // Add id to the list
                const updatedVisibleDisciplines = [...visibleDisciplines];
                updatedVisibleDisciplines.push(id);
                dispatch(saveVisibleDisciplines(updatedVisibleDisciplines));
            }
        }
    };

    const layerComponents = {
        legend: (
            <Legend hasCheckbox={true} hasIcons={true} visibleDisciplines={visibleDisciplines} onToggle={handleToggleVisibleDisciplines} />
        ),
        rooms: (
            <RoomsFilterAdvanced
                disciplineRoomsMapping={filters.disciplineRoomsMapping}
                disciplinesFilter={filters.disciplinesFilter}
                occupiedOpRooms={filters.occupiedOpRooms}
                roomsFilter={filters.roomsFilter}
                onSave={handleSaveRoomsFilter}
            />
        ),
        info: <InfoLayer />,
        print: <PrintLayer onClose={handleCloseLayer} />
    };
    const openLayerComponent = openedLayer && layerComponents[openedLayer];

    return (
        <Page
            data-testid="dayViewPage"
            fullActionMenubar={showFullActionMenubar}
            fullCanvas
            headerItems={headerItems}
            name="dayViewPage"
            openRightLayer={openedLayer === "info"} // only set true if info layer is opened
            organizationId={organizationId}
            renderOptions={renderHeaderOptions}
            showFullscreen={isFullscreen}
            title={t("DayViewPage.title")}
        >
            {isRejected(status) && <Message message={t("DayViewPage.loadError")} severity="error" />}

            <OpDetailsPopover anchorEl={anchorEl} handleClosePopover={handleClosePopover} isPopoverOpen={isPopoverOpen} />
            {opData.length > 0 && (
                <DayViewCanvas
                    handleOpenDetails={handleOpenDetails}
                    isCustomerPlan={isCustomerPlan}
                    isFullscreen={isFullscreen}
                    opData={opData}
                    opRooms={opRooms}
                    selectedOp={selectedOp}
                />
            )}

            {(isResolved(status) || isRejected(status)) && opData.length === 0 && (
                <NoOp
                    text1={t("App.notFoundOp1")}
                    text2={t("App.notFoundOp2", {
                        date: format(selectedDate, DATE_FORMATS.DATE)
                    })}
                />
            )}
            {!isFullscreen && (
                <ActionMenubar
                    actions={actions}
                    showFullActionMenubar={showFullActionMenubar}
                    onToggleWidth={handleToggleActionMenubarWidth}
                />
            )}
            <DetailRight
                fullActionMenubar={showFullActionMenubar}
                isFullscreen={isFullscreen}
                open={!!openedLayer}
                onClose={handleCloseLayer}
            >
                <div className={classes.rightLayerWrapper} data-testid={openedLayer}>
                    {openLayerComponent}
                </div>
            </DetailRight>
        </Page>
    );
};

export default DayView;
