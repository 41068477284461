// @ts-check
import axios from "axios";
import qs from "qs";

import {orchestratorPrefix} from "../../config/api_config";
import {authUserRecoverAction} from "../redux/actions";
import {selectAuthError} from "../redux/app_selectors";

let store;

export const injectStore = (_store) => {
    store = _store;
};

let token = null;
const CLIENT_ID_HEADER = "x-client-id";

export const setToken = (newToken) => {
    token = newToken;
};

const setRequestHeadersInterceptor = async (config) => {
    if (config.url.includes(global.LOG_URL) && global.HTTPLOG_BASIC_AUTH_TOKEN) {
        config.headers["Authorization"] = `Basic ${global.HTTPLOG_BASIC_AUTH_TOKEN}`;
    } else if (token) {
        config.headers["Authorization"] = "Bearer " + token;
    }
    config.headers[CLIENT_ID_HEADER] = global.CLIENT_ID;

    config.headers["Cache-Control"] = "no-cache";
    config.headers["Pragma"] = "no-cache";
    config.headers["Expires"] = "0";

    config.paramsSerializer = (params) => {
        return qs.stringify(params, {
            arrayFormat: "brackets",
            encode: false
        });
    };
    return config;
};

const axiosClient = axios.create({});

axiosClient.interceptors.request.use(setRequestHeadersInterceptor, (error) => {
    return Promise.reject(error);
});
// Add a response interceptor
axiosClient.interceptors.response.use(
    (response) => {
        const authError = selectAuthError(store.getState());
        const requestToOrchestrator = response.config.url.includes(orchestratorPrefix);
        const requestToIdentityServer = response.config.url.includes(global.IDENTITY_SERVER_AUTHORITY);

        if (authError && (requestToOrchestrator || requestToIdentityServer)) {
            store.dispatch(authUserRecoverAction());
        }
        return response;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default axiosClient;
