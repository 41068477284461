import {makeStyles} from "tss-react/mui";

export default makeStyles()((theme) => ({
    headline: {
        "display": "flex",
        "alignItems": "center",
        "margin": `0 0 ${theme.spacing("m")} 0`,
        "fontSize": "2.125rem",
        "fontWeight": "normal",
        "color": theme.palette.primary.main,
        "textTransform": "uppercase",

        "& span": {
            marginLeft: theme.spacing("s")
        }
    },
    intro: {
        margin: `${theme.spacing("s")} 0 ${theme.spacing("l")}`,
        fontSize: "1rem",
        fontWeight: 300
    },
    subheader: {
        "margin": `${theme.spacing("xl")} 0 ${theme.spacing("m")} 0`,
        "fontSize": "1rem",
        "fontWeight": 500,

        "&:after": {
            display: "inline",
            content: '":"'
        }
    }
}));
