// @ts-check
const ActionTypes = {
    CHANGE_DATE: "day_view/CHANGE_DATE",
    SAVE_OP_DATA: "day_view/SAVE_OP_DATA",
    SAVE_ANALYSED_OP_DATA: "day_view/SAVE_ANALYSED_OP_DATA",
    SAVE_OP_ROOMS: "day_view/SAVE_OP_ROOMS",
    LOAD_REQUEST: "day_view/LOAD_REQUEST",
    LOAD_SUCCESS: "day_view/LOAD_SUCCESS",
    LOAD_FAILURE: "day_view/LOAD_FAILURE",
    CLEAR_OP_DATA: "day_view/CLEAR_OP_DATA",
    SAVE_FILTERS: "day_view/SAVE_FILTERS",
    SAVE_VISIBLE_DISCIPLINES: "day_view/SAVE_VISIBLE_DISCIPLINES",
    LOAD_INFO_REQUEST: "day_view/LOAD_INFO_REQUEST",
    LOAD_INFO_SUCCESS: "day_view/LOAD_INFO_SUCCESS",
    LOAD_INFO_FAILURE: "day_view/LOAD_INFO_FAILURE",
    LOAD_PRINT_REQUEST: "day_view/LOAD_PRINT_REQUEST",
    LOAD_PRINT_SUCCESS: "day_view/LOAD_PRINT_SUCCESS",
    LOAD_PRINT_FAILURE: "day_view/LOAD_PRINT_FAILURE",
    LOAD_PRINT_ONCALL_REQUEST: "day_view/LOAD_PRINT_ONCALL_REQUEST",
    LOAD_PRINT_ONCALL_SUCCESS: "day_view/LOAD_PRINT_ONCALL_SUCCESS",
    LOAD_PRINT_ONCALL_FAILURE: "day_view/LOAD_PRINT_ONCALL_FAILURE",
    LOAD_CHANGE_HISTORY_REQUEST: "day_view/LOAD_CHANGE_HISTORY_REQUEST",
    LOAD_CHANGE_HISTORY_SUCCESS: "day_view/LOAD_CHANGE_HISTORY_SUCCESS",
    LOAD_CHANGE_HISTORY_FAILURE: "day_view/LOAD_CHANGE_HISTORY_FAILURE",
    RESET_OP_DATA_STATUS: "day_view/RESET_OP_DATA_STATUS",
    SET_HOVERED_OP: "day_view/SET_HOVERED_OP",
    SET_CLICKED_OP: "day_view/SET_CLICKED_OP"
};

export default ActionTypes;
