import HealingIcon from "@mui/icons-material/Healing";
import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import mainMenu from "../../../config/main_menu_structure";
import Page from "../../components/shared/page";
import {SitemapLinks} from "../../components/sitemap";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import useStyles from "./access_denied_page.styles";

const AccessDeniedPage = () => {
    const {classes} = useStyles();
    const {t} = useTranslation();

    const organizationId = useSelector(selectCurrentOrganizationId);

    return (
        <Page hasNotifications={false} name="accessDeniedPage" organizationId={organizationId} title={t("AccessDeniedPage.title")}>
            <h1 className={classes.headline}>
                <HealingIcon color="primary" fontSize="large" />
                <span>{t("AccessDeniedPage.headline")}</span>
            </h1>
            <p className={classes.intro}>{t("AccessDeniedPage.intro")}</p>

            <h2 className={classes.subheader}>{t("AccessDeniedPage.pages")}</h2>

            <SitemapLinks menu={mainMenu} />
        </Page>
    );
};

export default AccessDeniedPage;
