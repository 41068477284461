import {createReducer} from "../../redux/utils/reducer";
import STATUS from "../../redux/utils/status";
import {getLocalStorageItem, setLocalStorageItem} from "../../utils/local_storage";
import ActionTypes from "./day_view_action_types";

const initialState = {
    opDataStatus: STATUS.IDLE,
    printStatus: STATUS.IDLE,
    printOnCallStatus: STATUS.IDLE,
    changeHistoryStatus: STATUS.IDLE,
    selectedDate: null,
    opData: [],
    scrollbar: null,
    opRooms: [],
    filters: {
        roomsFilter: getLocalStorageItem("DayView", "roomsFilter") || [],
        disciplinesFilter: getLocalStorageItem("DayView", "disciplinesFilter") || [],
        occupiedOpRooms: [],
        disciplineRoomsMapping: {}
    },
    visibleDisciplines: getLocalStorageItem("DayView", "visibleDisciplines") || [],
    infoStatus: STATUS.IDLE,
    infoLayer: {changeHistory: null, info1: null, info2: null, info3: null},
    printSurgeries: {}, // save data in date {"2022-08-22": []}
    printOnCall: {},
    changeHistory: [],
    hoveredOp: null,
    clickedOp: null
};

const handleChangeDate = (state, {payload}) => ({
    ...state,
    selectedDate: payload
});

const handleSaveOpData = (state, {payload}) => ({
    ...state,
    opData: payload
});

const handleSaveOpRooms = (state, {payload}) => ({
    ...state,
    opRooms: payload
});

const handleLoadRequest = (state) => ({
    ...state,
    opDataStatus: STATUS.PENDING,
    error: null
});
const handleLoadFailure = (state, {error}) => ({
    ...state,
    opDataStatus: STATUS.REJECTED,
    error
});
const handleLoadSuccess = (state, {payload, status}) => ({
    ...state,
    opDataStatus: status,
    error: null,
    opData: payload
});

const handleClearOpData = (state) => ({
    ...state,
    opDataStatus: STATUS.IDLE,
    opData: []
});
const handleSaveFilters = (state, {payload}) => {
    setLocalStorageItem("DayView", "roomsFilter", payload.roomsFilter);
    setLocalStorageItem("DayView", "disciplinesFilter", payload.disciplinesFilter);
    return {
        ...state,
        filters: {...payload}
    };
};

const handleVisibleDisciplines = (state, {visibleDisciplines}) => {
    setLocalStorageItem("DayView", "visibleDisciplines", visibleDisciplines);
    return {
        ...state,
        visibleDisciplines
    };
};

const handleLoadInfoRequest = (state) => ({
    ...state,
    infoStatus: STATUS.PENDING,
    error: null
});
const handleLoadInfoFailure = (state, {error}) => ({
    ...state,
    infoStatus: STATUS.REJECTED,
    error
});
const handleLoadInfoSuccess = (state, {payload}) => ({
    ...state,
    infoStatus: STATUS.RESOLVED,
    error: null,
    infoLayer: {...payload}
});

const handleLoadPrintRequest = (state) => ({
    ...state,
    printStatus: STATUS.PENDING,
    error: null
});
const handleLoadPrintFailure = (state, {error}) => ({
    ...state,
    printStatus: STATUS.REJECTED,
    error
});
const handleLoadPrintSuccess = (state, {payload, date}) => ({
    ...state,
    printStatus: STATUS.RESOLVED,
    error: null,
    printSurgeries: {
        ...state.printSurgeries,
        [date]: payload
    }
});

const handleLoadPrintOnCallRequest = (state) => ({
    ...state,
    printOnCallStatus: STATUS.PENDING,
    error: null
});
const handleLoadPrintOnCallFailure = (state, {error}) => ({
    ...state,
    printOnCallStatus: STATUS.REJECTED,
    error
});
const handleLoadPrintOnCallSuccess = (state, {payload, date}) => ({
    ...state,
    printOnCallStatus: STATUS.RESOLVED,
    error: null,
    printOnCall: {
        ...state.printOnCall,
        [date]: payload
    }
});

const handleLoadChangeHistoryRequest = (state) => ({
    ...state,
    changeHistoryStatus: STATUS.PENDING,
    error: null
});
const handleLoadChangeHistoryFailure = (state, {error}) => ({
    ...state,
    changeHistoryStatus: STATUS.REJECTED,
    error
});
const handleLoadChangeHistorySuccess = (state, {payload}) => ({
    ...state,
    changeHistoryStatus: STATUS.RESOLVED,
    error: null,
    changeHistory: payload
});

const resetOpDataStatus = () => ({
    opDataStatus: STATUS.IDLE
});

const handleHoveredOp = (state, {opId}) => ({
    ...state,
    hoveredOp: opId
});
const handleClickedOp = (state, {opId}) => ({
    ...state,
    clickedOp: opId
});

const handlers = {
    [ActionTypes.CHANGE_DATE]: handleChangeDate,
    [ActionTypes.SAVE_OP_DATA]: handleSaveOpData,
    [ActionTypes.SAVE_OP_ROOMS]: handleSaveOpRooms,
    [ActionTypes.LOAD_REQUEST]: handleLoadRequest,
    [ActionTypes.LOAD_SUCCESS]: handleLoadSuccess,
    [ActionTypes.LOAD_FAILURE]: handleLoadFailure,
    [ActionTypes.CLEAR_OP_DATA]: handleClearOpData,
    [ActionTypes.SAVE_FILTERS]: handleSaveFilters,
    [ActionTypes.SAVE_VISIBLE_DISCIPLINES]: handleVisibleDisciplines,
    [ActionTypes.LOAD_INFO_REQUEST]: handleLoadInfoRequest,
    [ActionTypes.LOAD_INFO_SUCCESS]: handleLoadInfoSuccess,
    [ActionTypes.LOAD_INFO_FAILURE]: handleLoadInfoFailure,
    [ActionTypes.LOAD_PRINT_REQUEST]: handleLoadPrintRequest,
    [ActionTypes.LOAD_PRINT_SUCCESS]: handleLoadPrintSuccess,
    [ActionTypes.LOAD_PRINT_FAILURE]: handleLoadPrintFailure,
    [ActionTypes.LOAD_PRINT_ONCALL_REQUEST]: handleLoadPrintOnCallRequest,
    [ActionTypes.LOAD_PRINT_ONCALL_SUCCESS]: handleLoadPrintOnCallSuccess,
    [ActionTypes.LOAD_PRINT_ONCALL_FAILURE]: handleLoadPrintOnCallFailure,
    [ActionTypes.LOAD_CHANGE_HISTORY_REQUEST]: handleLoadChangeHistoryRequest,
    [ActionTypes.LOAD_CHANGE_HISTORY_SUCCESS]: handleLoadChangeHistorySuccess,
    [ActionTypes.LOAD_CHANGE_HISTORY_FAILURE]: handleLoadChangeHistoryFailure,
    [ActionTypes.RESET_OP_DATA_STATUS]: resetOpDataStatus,
    [ActionTypes.SET_HOVERED_OP]: handleHoveredOp,
    [ActionTypes.SET_CLICKED_OP]: handleClickedOp
};
export default createReducer(initialState, handlers);
