import {makeStyles} from "tss-react/mui";

/** @type {Object} */
export default makeStyles()((theme) => ({
    track: {
        backgroundColor: theme.palette.grey[300]
    },
    zoomButton: {
        "&:hover": {
            backgroundColor: "transparent"
        }
    },
    icon: {
        "width": "2.5rem",
        "height": "2.5rem",
        "borderRadius": "1.25rem",
        "padding": theme.spacing("s"),
        "&:hover": {
            backgroundColor: theme.palette.primary.light
        }
    },
    isOpened: {
        color: theme.palette.primary.main,
        backgroundColor: theme.palette.primary.light
    },
    rightLayerWrapper: {width: "100%", display: "flex"}
}));
