import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import ActionItems from "../../components/action_items/action_items";
import AdminUserCanvas from "../../components/admin_user_canvas/admin_user_canvas";
import AdminUserLayer from "../../components/admin_user_layer/admin_user_layer";
import DetailDialog from "../../components/shared/detail_dialog/detail_dialog";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import usePrevious from "../../hooks/usePrevious";
import {selectCurrentOrganizationId, selectUserStrategy} from "../../redux/app_selectors";
import {isRejected, isResolved} from "../../redux/utils/status";
import {PERMISSION, useSecurity} from "../../utils/security";
import {selectLoadStatus, selectSaveStatus} from "./admin_user_selectors";
import {getCenterActionItems} from "./utils/admin_user_action_items";

const AdminUserPage = () => {
    const {t} = useTranslation();

    const [openChange, setOpenChange] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [message, setMessage] = useState(null);
    const [saved, setSaved] = useState(false);

    // redux
    const organizationId = useSelector(selectCurrentOrganizationId);
    const loadStatus = useSelector(selectLoadStatus);
    const saveStatus = useSelector(selectSaveStatus);
    const {addUser: isAddUserAllowed} = useSelector(selectUserStrategy);

    const prevSaveStatus = usePrevious(saveStatus);
    const {isGranted} = useSecurity();

    useEffect(() => {
        if (prevSaveStatus && saveStatus !== prevSaveStatus && isResolved(saveStatus)) {
            setMessage(t("AdminUserPage.saveSuccess"));
            setTimeout(() => {
                setMessage(null);
            }, 5000);

            handleCloseChange();
            setSelectedUser(null);
            setSaved(true);
        }
    }, [saveStatus]);

    // handlers for layer open
    const handleOpenChange = () => {
        if (!openChange) {
            setOpenChange(true);
            setSaved(false);
        } else {
            setOpenChange(false);
        }
    };

    // handlers for layer close
    const handleCloseChange = () => {
        setOpenChange(false);
        setSelectedUser(null);
    };

    // Set centered action items
    const hasActionsCenter = isGranted(PERMISSION.MODIFY_USER) && isAddUserAllowed;
    let actionsCenter = [];
    const handlersCenter = {
        handleOpenChange: handleOpenChange
    };
    const isOpened = {
        openChange
    };
    actionsCenter = hasActionsCenter ? getCenterActionItems(handlersCenter, isOpened) : [];

    const handleEdit = (slot) => {
        setSelectedUser(slot);
        handleOpenChange();
    };

    return (
        <Page minPadding name="userPage" organizationId={organizationId} title={t("AdminUserPage.title")}>
            {isRejected(loadStatus) && <Message message={t("AdminUserPage.loadError")} severity="error" />}
            {message && <Message message={message} />}
            <ActionItems actionsCenter={actionsCenter} />
            {organizationId && <AdminUserCanvas saved={saved} onEdit={handleEdit} />}
            {openChange && (
                <DetailDialog open={openChange} onClose={handleCloseChange}>
                    <AdminUserLayer user={selectedUser} onClose={handleCloseChange} />
                </DetailDialog>
            )}
        </Page>
    );
};

export default AdminUserPage;
