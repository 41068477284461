import {createReducer} from "../../redux/utils/reducer";
import ActionTypes from "./availability_planner_action_types";

const DEFAULT_INTERVAL = 7; // days

const initialState = {
    date: null,
    interval: DEFAULT_INTERVAL
};

const handleChangeDate = (state, {date, interval}) => ({
    ...state,
    date,
    interval
});

const handlers = {
    [ActionTypes.CHANGE_DATE]: handleChangeDate
};

export default createReducer(initialState, handlers);
