// @ts-check
/**
 * @fileoverview redux selectors for Employees
 */

import {createSelector} from "reselect";

const selectDayView = (state) => state.dayView;

const selectSelectedDate = (state) => selectDayView(state).selectedDate;

const selectOpData = (state) => state.dayView.opData;

const selectOpRooms = (state) => state.dayView.opRooms;

const selectOpDataStatus = (state) => state.dayView.opDataStatus;

const selectFilters = (state) => state.dayView.filters;

const selectVisibleDisciplines = (state) => state.dayView.visibleDisciplines;

const selectInfo1 = createSelector(selectDayView, (dayViewState) => dayViewState.infoLayer.info1 || []);
const selectInfo2 = createSelector(selectDayView, (dayViewState) => dayViewState.infoLayer.info2 || []);
const selectInfo3 = createSelector(selectDayView, (dayViewState) => dayViewState.infoLayer.info3 || []);

const selectPrintSurgeries = (state) => selectDayView(state).printSurgeries;
const selectPrintOnCall = (state) => selectDayView(state).printOnCall;

const selectChangeHistory = (state) => selectDayView(state).changeHistory;

const selectHoveredOp = (state) => selectDayView(state).hoveredOp;
const selectClickedOp = (state) => selectDayView(state).clickedOp;

export {
    selectSelectedDate,
    selectOpData,
    selectOpRooms,
    selectOpDataStatus,
    selectFilters,
    selectVisibleDisciplines,
    selectInfo1,
    selectInfo2,
    selectInfo3,
    selectChangeHistory,
    selectPrintSurgeries,
    selectPrintOnCall,
    selectHoveredOp,
    selectClickedOp
};
