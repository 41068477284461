/**
 * @fileoverview redux selectors for timelots
 */

/**
 * @typedef {Object} ErrorUserData
 * @property {string} value
 * @property {string} msg
 * @property {"firstName" | "lastName" | "email" | "roles" | "practitionerId" | "passwordConfirm" | "status"} param
 * @property {string} location
 */

/**
 * @typedef AdminUserSlice
 * @type {Object}
 * @property {Array<UserAPI>} data
 * @property {Status} loadStatus
 * @property {Status} saveStatus
 * @property {null|string} error
 * @property {Array<{name: string}>} roles
 * @property {Array<ErrorUserData>} saveError
 */

/**
 * Selects the adminUser slice of the store
 *
 * @param {*} state
 * @return {AdminUserSlice}
 */
const selectAdminUser = (state) => state.adminUser;

const selectLoadStatus = (state) => selectAdminUser(state).loadStatus;
const selectSaveStatus = (state) => selectAdminUser(state).saveStatus;
const selectData = (state) => selectAdminUser(state).data;
const selectRoles = (state) => selectAdminUser(state).roles;
const selectSaveError = (state) => selectAdminUser(state).saveError;

export {selectLoadStatus, selectData, selectSaveStatus, selectRoles, selectSaveError};
