import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import Account from "../../components/account/account";
import Page from "../../components/shared/page";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";

/**
 * Page to show account data
 * @return {jsx}
 */
const AccountPage = () => {
    const {t} = useTranslation();
    const organizationId = useSelector(selectCurrentOrganizationId);

    return (
        <Page data-testid="accountPage" hasNoPadding name="accountPage" organizationId={organizationId} title={t("Account.title")}>
            <Account />
        </Page>
    );
};

export default AccountPage;
