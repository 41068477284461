import config from "../../../config/config.json";

/**
 * get action items
 * @param {object} handlers
 * @param {object} isOpened
 * @param {String} mode
 * @param {Boolean} isModifyAllowed
 * @return {array}
 */
const getActions = (handlers, isOpened, mode, isModifyAllowed) => {
    const actions = [
        {
            iconName: "MenuBook",
            disabled: false,
            handler: handlers.openLegend,
            translationKey: "AvailabilityPlannerPage.legend",
            isOpened: isOpened.isLegendVisible
        }
    ];
    // add absence action
    if (isModifyAllowed) {
        actions.unshift({
            iconName: "AbsenceIcon",
            disabled: false,
            handler: handlers.openDialogUnavailable,
            translationKey: "AvailabilityPlannerPage.unavailable",
            isOpened: isOpened.isDialogAbsenceVisible,
            color: "danger"
        });
    }

    // add availability action
    if (isModifyAllowed && (!mode || !config.DISABLE_AVAILABILITY_MODES.includes(mode))) {
        actions.unshift({
            iconName: "AvailableIcon",
            disabled: false,
            handler: handlers.openDialogAvailable,
            translationKey: "AvailabilityPlannerPage.available",
            isOpened: isOpened.isDialogAvailableVisible
        });
    }
    return actions;
};

export {getActions};
