// @ts-check
/**
 * @fileoverview availability planner page to show availabilities and blockers
 */

import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";

import config from "../../../config/config.json";
import {EmployeeAvailabilityDialogContainer} from "../../components/employee_availability";
import {clearSlotAction} from "../../components/employee_availability";
import {EmployeesAvailabilitiesContainer} from "../../components/employees_availabilities";
import {selectAvailabilityMode} from "../../components/employees_availabilities/employees_availabilities_selectors";
import Legend from "../../components/legend/legend";
import ActionMenubar from "../../components/shared/action_menubar/action_menubar";
import DetailRight from "../../components/shared/detail_right/detail_right";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import WeekSelector from "../../components/shared/week_selector";
import {DateContext} from "../../contexts/dates";
import useToggle from "../../hooks/useToggle";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {PERMISSION, useSecurity} from "../../utils/security";
import {getActions} from "./availability_planner_action_items";
import {changeDate} from "./availability_planner_actions";
import {selectAvailabilityPlannerDateFrom, selectAvailabilityPlannerDateTo} from "./availability_planner_selectors";

/**
 * @typedef {Object} DefaultAvailabilitySlot
 * @property {Variant} [variant]
 * @property {DateTimeType} [start]
 * @property {DateTimeType} [end]
 * @property {String} [empId]
 * @property {Array<{slotId: String}>} [periods]
 */

/**
 * Render AvailabilityPlannerPage component
 * @return {React.ReactElement}
 */
const AvailabilityPlannerPage = () => {
    const {t} = useTranslation();
    const dispatch = useDispatch();
    const {isGranted} = useSecurity();
    const {now, startOf} = useContext(DateContext);

    const organizationId = useSelector(selectCurrentOrganizationId);
    const periodFrom = useSelector(selectAvailabilityPlannerDateFrom);
    const periodTo = useSelector(selectAvailabilityPlannerDateTo);
    // @link https://next-data-service.visualstudio.com/13394-nextOR/_wiki/wikis/13394-NextOR-Wiki/875/Availability
    const availabilityMode = useSelector(selectAvailabilityMode);

    const [isLegendVisible, toggleLegend] = useToggle(false);
    // eslint-disable-next-line no-unused-vars
    const [isDialogAvailableVisible, toggleDialogAvailable, toggleDialogAvailableOn, toggleDialogAvailableOff] = useToggle(false);
    // eslint-disable-next-line no-unused-vars
    const [isDialogAbsenceVisible, toggleDialogAbsence, toggleDialogAbsenceOn, toggleDialogAbsenceOff] = useToggle(false);
    /** @type [DefaultAvailabilitySlot, Function] */
    const [slot, setSlot] = useState({});
    const [message, setMessage] = useState(null);
    const [showWeekend, setShowWeekend] = useState(false);
    const [showFullActionMenubar, setShowFullActionMenubar] = useState(false);

    useEffect(() => {
        // init with start of week
        if (!periodFrom) {
            dispatch(changeDate(startOf(now(), "week"), 7));
        }

        // if periodFrom is set
        if (periodFrom) {
            dispatch(changeDate(periodFrom, 7));
        }
    }, [periodFrom, periodTo, showWeekend]);

    const handleDateChange = (date) => {
        dispatch(changeDate(startOf(date, "week"), 7));
    };
    const handleClose = () => {
        dispatch(clearSlotAction());
        toggleDialogAvailableOff();
        toggleDialogAbsenceOff();
        setSlot({});
    };

    const handleSave = () => {
        setMessage({severity: "success", text: t("AvailabilityPlannerPage.saved")});
    };

    const handleError = () => {
        setMessage({severity: "error", text: t("AvailabilityPlannerPage.error")});
    };

    const handleDelete = () => {
        setMessage({severity: "success", text: t("AvailabilityPlannerPage.deleted")});
    };

    const handleMessageClose = () => {
        setMessage(null);
    };

    const handleNew = (type) => {
        dispatch(clearSlotAction());
        if (
            (type === config.AVAILABILITY_VARIANTS.AVAILABILITY && !isDialogAvailableVisible) ||
            (type === config.AVAILABILITY_VARIANTS.ABSENCE && !isDialogAbsenceVisible)
        ) {
            setSlot({
                start: now(),
                end: now(),
                variant: type
            });
            if (type === config.AVAILABILITY_VARIANTS.AVAILABILITY) {
                toggleDialogAvailableOn();
                toggleDialogAbsenceOff();
            } else {
                toggleDialogAbsenceOn();
                toggleDialogAvailableOff();
            }
            if (isLegendVisible) {
                toggleLegend();
            }
        } else {
            toggleDialogAvailableOff();
            toggleDialogAbsenceOff();
        }
    };

    const handleAdd = ({date, ...payload}) => {
        dispatch(clearSlotAction());
        if (
            (payload.variant === config.AVAILABILITY_VARIANTS.AVAILABILITY && !isDialogAvailableVisible) ||
            (payload.variant === config.AVAILABILITY_VARIANTS.ABSENCE && !isDialogAbsenceVisible)
        ) {
            setSlot({
                ...payload,
                start: date,
                end: date
            });
            if (payload.variant === config.AVAILABILITY_VARIANTS.AVAILABILITY) {
                toggleDialogAvailableOn();
                toggleDialogAbsenceOff();
            } else {
                toggleDialogAbsenceOn();
                toggleDialogAvailableOff();
            }
            if (isLegendVisible) {
                toggleLegend();
            }
        } else {
            toggleDialogAvailableOff();
            toggleDialogAbsenceOff();
        }
    };

    const handleToggleLegend = () => {
        if (!isLegendVisible) {
            toggleDialogAvailableOff();
            toggleDialogAbsenceOff();
        }
        toggleLegend();
    };

    // Set actions for right action menubar
    const handlers = {
        openDialogAvailable: () => handleNew(config.AVAILABILITY_VARIANTS.AVAILABILITY),
        openDialogUnavailable: () => handleNew(config.AVAILABILITY_VARIANTS.ABSENCE),
        openLegend: handleToggleLegend
    };
    const isOpened = {
        isDialogAvailableVisible,
        isDialogAbsenceVisible,
        isLegendVisible
    };
    const isAllowed = isGranted(PERMISSION.MODIFY_PRACTITIONER_OWN) || isGranted(PERMISSION.MODIFY_PRACTITIONER);
    const actions = getActions(handlers, isOpened, availabilityMode, isAllowed);

    const renderHeaderOptions = () =>
        periodFrom &&
        periodTo && (
            <WeekSelector
                from={periodFrom}
                showWeekend={showWeekend}
                to={periodTo}
                onChange={handleDateChange}
                onToggleShowWeekend={toggleShowWeekend}
            />
        );

    if (!organizationId || !periodFrom || !periodTo) {
        return null;
    }

    /**
     * handler to toggle show weekend
     */
    const toggleShowWeekend = () => {
        setShowWeekend(!showWeekend);
    };

    /**
     * handler to toggle action menubar width
     */
    const toggleActionMenubar = () => {
        setShowFullActionMenubar(!showFullActionMenubar);
    };

    return (
        <Page
            data-testid="availabilityPage"
            fullActionMenubar={showFullActionMenubar}
            fullCanvas
            name="availabilityPage"
            openRightLayer={isDialogAvailableVisible || isDialogAbsenceVisible || isLegendVisible}
            organizationId={organizationId}
            renderOptions={renderHeaderOptions}
            title={t("AvailabilityPlannerPage.title")}
        >
            {(isDialogAvailableVisible || isDialogAbsenceVisible) && (
                <DetailRight
                    fullActionMenubar={showFullActionMenubar}
                    open={slot.variant === config.AVAILABILITY_VARIANTS.AVAILABILITY ? isDialogAvailableVisible : isDialogAbsenceVisible}
                    onClose={handleClose}
                >
                    <EmployeeAvailabilityDialogContainer
                        dateFrom={slot.start}
                        dateTo={slot.end}
                        empId={slot.empId}
                        periods={slot.periods}
                        variant={slot.variant}
                        onDelete={handleDelete}
                        onError={handleError}
                        onSave={handleSave}
                    />
                </DetailRight>
            )}
            {isLegendVisible && (
                <DetailRight fullActionMenubar={showFullActionMenubar} open={isLegendVisible} onClose={toggleLegend}>
                    <Legend />
                </DetailRight>
            )}
            <EmployeesAvailabilitiesContainer
                from={periodFrom}
                openRightLayer={isDialogAvailableVisible || isDialogAbsenceVisible}
                showWeekend={showWeekend}
                to={periodTo}
                onAdd={handleAdd}
            />
            <ActionMenubar actions={actions} showFullActionMenubar={showFullActionMenubar} onToggleWidth={toggleActionMenubar} />
            {message && <Message message={message.text} severity={message.severity} onClose={handleMessageClose} />}
        </Page>
    );
};

export default AvailabilityPlannerPage;
