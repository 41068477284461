/**
 * get centered action items
 * @param {object} handlers
 * @param {object} isOpened
 * @return {array}
 */
const getCenterActionItems = (handlers, isOpened) => {
    const actionsCenter = [
        {
            iconName: "AddBox",
            disabled: false,
            handler: handlers.handleOpenChange,
            translationKey: "AdminUserPage.change",
            isOpened: isOpened.openChange
        }
    ];
    return actionsCenter;
};

export {getCenterActionItems};
