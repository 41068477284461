const ActionTypes = {
    LOAD_REQUEST: "adminUser/LOAD_USERS_REQUEST",
    LOAD_SUCCESS: "adminUser/LOAD_USERS_SUCCESS",
    LOAD_FAILURE: "adminUser/LOAD_USERS_FAILURE",
    SAVE_REQUEST: "adminUser/SAVE_USER_REQUEST",
    SAVE_SUCCESS: "adminUser/SAVE_USER_SUCCESS",
    SAVE_FAILURE: "adminUser/SAVE_USER_FAILURE",
    LOAD_ROLES: "adminUser/LOAD_ROLES"
};

export default ActionTypes;
