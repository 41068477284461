import React from "react";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";

import AdminDisciplinesCanvas from "../../components/admin_disciplines_canvas/admin_disciplines_canvas";
import {selectStatus} from "../../components/disciplines/disciplines_selectors";
import Message from "../../components/shared/message/message";
import Page from "../../components/shared/page";
import {selectCurrentOrganizationId} from "../../redux/app_selectors";
import {isRejected} from "../../redux/utils/status";

const AdminDisciplinesPage = () => {
    const {t} = useTranslation();

    const organizationId = useSelector(selectCurrentOrganizationId);
    const loadStatus = useSelector(selectStatus);

    return (
        <Page minPadding organizationId={organizationId} title={t("AdminDisciplinesPage.title")}>
            {isRejected(loadStatus) && <Message message={t("AdminDisciplinesPage.loadError")} severity="error" />}
            {organizationId && <AdminDisciplinesCanvas />}
        </Page>
    );
};

export default AdminDisciplinesPage;
