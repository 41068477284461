// @ts-check
/**
 * Get the action that will be displayed in the Action Menu Bar
 * @param {{handleOpenRooms: Function, handleOpenLegend: Function, handleOpenInfo: Function, handleOpenPrint: Function}} handlers
 * @param {RightDetailLayer} openedLayer
 * @param {{isInfoDisabled: Boolean}} disabled
 * @param {Boolean} isPrintEnabled
 * @return {Array<ActionMenu>} The array of actions
 */
export const getActions = (handlers, openedLayer, disabled, isPrintEnabled) => {
    const actions = [
        {
            iconName: "LineStyle",
            handler: handlers.handleOpenRooms,
            translationKey: "DayViewPage.rooms",
            isOpened: openedLayer === "rooms"
        },
        {
            iconName: "MenuBook",
            handler: handlers.handleOpenLegend,
            translationKey: "DayViewPage.legend",
            isOpened: openedLayer === "legend"
        },
        {
            iconName: "InfoIcon",
            handler: handlers.handleOpenInfo,
            translationKey: "DayViewPage.info",
            isOpened: openedLayer === "info",
            disabled: disabled.isInfoDisabled
        }
    ];
    if (isPrintEnabled) {
        actions.push({
            iconName: "Print",
            handler: handlers.handleOpenPrint,
            translationKey: "DayViewPage.print",
            isOpened: openedLayer === "print"
        });
    }
    return actions;
};
