// @ts-check

import {luxonToken} from "../../contexts/dates";
import {format, getDateTimeFromISO, startOf} from "../../utils/luxon_helpers";

/**
 * @callback FilterChangesCallback
 *
 * @param {ResourceHistoryChange} change
 * @return {Boolean}
 */

/**
 * Filter the changes by timestamp and start
 *
 * @param {Array<ChangeLogOp>} changeLogOps
 * @param {String} date date string in ISO 8601 format
 * @param {String} publishedAt date string in ISO 8601 format
 * @return {FilterChangesCallback}
 */
const filterChanges = (changeLogOps, date, publishedAt) => (change) => {
    const resourceId = change.resourceUri.split("/")[1];
    const changeLogOp = changeLogOps.find((op) => op.id === resourceId);

    // Check if the op start is today
    const isStartToday = changeLogOp?.start && format(getDateTimeFromISO(changeLogOp.start), luxonToken.SYSTEM_DATE.de) === date;

    // Check if the timestamp of the change earlier than last published time
    const isBeforeLastPublish =
        change?.timestamp && publishedAt && getDateTimeFromISO(publishedAt) >= startOf(getDateTimeFromISO(change.timestamp), "minute"); // ignore seconds
    return isStartToday && isBeforeLastPublish;
};

/**
 * @callback FormatChangesCallback
 *
 * @param {ResourceHistoryChange} change
 * @return {ResourceHistoryChangeInfoLayer}
 */

/**
 * format the changes for the info layer
 *
 * @param {Array<ChangeLogOp>} changeLogOps
 * @return {FormatChangesCallback}
 */
const formatChanges = (changeLogOps) => (change) => {
    const resourceId = change.resourceUri.split("/")[1];
    const changeLogOp = changeLogOps.find((op) => op.id === resourceId);
    return {
        id: changeLogOp?.id,
        patientId: changeLogOp?.patientId,
        start: changeLogOp?.start,
        isEmergency: changeLogOp?.isEmergency,
        change
    };
};

export {filterChanges, formatChanges};
