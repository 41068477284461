import ActionTypes from "./availability_planner_action_types";

/**
 * change date period
 * @param {Date|DateTimeType} date    the new start date
 * @param {number} interval    interval for end date
 * @return {Object}
 */
const changeDate = (date, interval) => ({
    type: ActionTypes.CHANGE_DATE,
    date,
    interval
});

export {changeDate};
