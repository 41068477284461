import {createSelector} from "reselect";

import {plusDT} from "../../utils/luxon_helpers";

const selectAvailabilityPlanner = (state) => state.availabilityPlanner;

const selectAvailabilityPlannerInterval = (state) => selectAvailabilityPlanner(state).interval;
const selectAvailabilityPlannerDateFrom = (state) => selectAvailabilityPlanner(state).date;
const selectAvailabilityPlannerDateTo = createSelector(
    selectAvailabilityPlannerDateFrom,
    selectAvailabilityPlannerInterval,
    (date, interval) => (date ? plusDT(date, "day", interval - 1) : null)
);

export {selectAvailabilityPlannerInterval, selectAvailabilityPlannerDateFrom, selectAvailabilityPlannerDateTo};
